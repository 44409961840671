<template style="background: #E5E5E5">
  <v-container fluid>
    <v-row class="mt-4" align="start">
      <v-col v-if="!isFreeMovement" cols="12">
        <p class="title my-0">
          Titular:
          <span style="font-weight: bold">
            {{ holderName ? formatter.formatToTitleCase(holderName) : "Carregando titular..." }}
          </span>
        </p>
      </v-col>
      <v-col cols="12">
        <v-form v-if="isFreeMovement" ref="formHolderInfo">
          <PrincipalInformations
            :holderInformationData="holder"
            :showHeaderPage="false"
            :isDetail="isFreeMovement ? false : true"
            :showAdmissionDate="false"
            :showCardNumber="true"
            :showRegistrationPlate="true"
            @observerHolderInformationData="observerHolderInformationData"
          />
        </v-form>
      </v-col>
      <v-col>
        <v-form v-if="isFreeMovement" ref="formPlanInfo">
          <PlanInformation
            class="py-0"
            :contractedPlanData="contractedPlanData"
            :isDetail="isFreeMovement ? false : true"
            :showHeaderPage="false"
            :showEligibility="false"
            :showStartValidityDate="false"
            :showEndValidityDate="false"
            :showCancellationReason="false"
            :showFamilyCode="false"
            :showFamilyCodeSequential="false"
            @observerContractedPlan="observerContractedPlan"
          />
        </v-form>
      </v-col>
    </v-row>

    <v-row align="center" class="pb-5">
      <v-col cols="12">
        <v-form ref="formRegister" v-model="valid" lazy-validation>
          <v-card elevation="1" :loading="loading">
            <v-card-title>
              <v-row>
                <v-col cols="12" md="6">
                  <p class="title">Alteração de Dependente</p>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="dependent.document"
                    label="CPF"
                    placeholder="Informe"
                    v-mask="'###.###.###-##'"
                    :rules="dependent.document ? [rule.cpf] : []"
                    validate-on-blur
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                  <v-text-field
                    v-model.trim="dependent.fullName"
                    label="Nome completo"
                    maxlength="70"
                    placeholder="Informe"
                    :rules="dependent.fullName ? [rule.validateIsAlphanumeric, rule.name] : []"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    @blur="dependent.fullName = formatter.formatToTitleCase(dependent.fullName)"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <div class="d-flex flex-column">
                    <v-text-field
                      outlined
                      label="Data de nascimento"
                      v-model="dependent.birthDate"
                      append-icon="fas fa-calendar-alt"
                      placeholder="DD/MM/YYYY"
                      v-mask="'##/##/####'"
                      color="textPrimary"
                      :rules="dependent.birthDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                      validate-on-blur
                      :disabled="loading"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete
                    v-model="dependent.stateBirth"
                    :items="states"
                    item-text="label"
                    item-value="label"
                    outlined
                    label="UF de Nascimento"
                    color="textPrimary"
                    item-color="textPrimary"
                    append-icon="fas fa-chevron-down"
                    clearable
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete
                    :items="genders"
                    v-model="dependent.gender"
                    item-text="description"
                    item-value="id"
                    label="Gênero"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                  <v-text-field
                    v-model.trim="dependent.motherName"
                    label="Nome da mãe"
                    maxlength="70"
                    placeholder="Informe"
                    :rules="dependent.motherName ? [rule.validateIsAlphanumeric, rule.name] : []"
                    validate-on-blur
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    @blur="dependent.motherName = formatter.formatToTitleCase(dependent.motherName)"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-autocomplete
                    :items="kinships"
                    v-model="dependent.kinship"
                    item-text="description"
                    item-value="id"
                    label="Grau de parentesco"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="dependent.cns"
                    label="CNS"
                    placeholder="Informe o CNS"
                    outlined
                    v-mask="'###############'"
                    :rules="dependent.cns ? [rule.cns] : []"
                    validate-on-blur
                    color="textPrimary"
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="dependent.dnv"
                    label="Declaração de Nascido Vivo (DNV)"
                    v-mask="'##-########-#'"
                    placeholder="Informe o DNV"
                    outlined
                    color="textPrimary"
                    :rules="dependent.dnv ? [rule.dnv] : []"
                    validate-on-blur
                    :disabled="loading"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    label="Peso(kg)"
                    v-mask="'###'"
                    type="number"
                    min="1"
                    placeholder="Informe o peso"
                    v-model="dependent.weight"
                    @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                    @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight)"
                    @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                    id="fieldCheckedWeight"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    label="Altura(cm)"
                    v-mask="'###'"
                    type="number"
                    min="1"
                    placeholder="Informe a altura"
                    v-model="dependent.height"
                    @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                    @input="dependent.height = formatter.removeInvalidNumber(dependent.height);"
                    @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                    id="fieldCheckedHeight"
                    outlined
                    :disabled="loading"
                    color="textPrimary"
                    :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
                  />
                </v-col>
                <v-col v-if="conditionShowEventDate()" cols="12" sm="6" md="4" lg="3" xl="2">
                  <div class="d-flex flex-column">
                    <v-text-field
                      outlined
                      label="Data do evento"
                      v-model="dependent.eventDate"
                      append-icon="fas fa-calendar-alt"
                      placeholder="DD/MM/YYYY"
                      v-mask="'##/##/####'"
                      color="textPrimary"
                      :disabled="loading"
                      :rules="dependent.eventDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow, rule.requiredDate] : []"
                      validate-on-blur
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" :xl="conditionShowEventDate() ? '3' : '2'">
                  <div class="d-flex flex-column">
                    <v-text-field
                      outlined
                      label="Data de vigência (DD/MM/YYYY)"
                      v-model="dependent.validityDate"
                      append-icon="fas fa-calendar-alt"
                      placeholder="Informe a data de vigência do plano"
                      v-mask="'##/##/####'"
                      color="textPrimary"
                      :disabled="loading"
                      :rules="dependent.validityDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.requiredDate] : []"
                      validate-on-blur
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete
                    :items="maritalStatus"
                    v-model="dependent.maritalStatus"
                    label="Estado civil"
                    item-text="description"
                    item-value="id"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    label="Código Familiar"
                    outlined
                    v-model="dependent.familyCode"
                    v-mask="`${ formatter.maskLimiter(10) }`"
                    color="textPrimary"
                    placeholder="Informe código familiar"
                    :disabled="isDisabledFamilyCode"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    label="Sequencial"
                    outlined
                    v-model="dependent.familyCodeSequential"
                    v-mask="`${ formatter.maskLimiter(5) }`"
                    color="textPrimary"
                    placeholder="Informe código sequencial"
                    :disabled="isDisabledFamilyCodeSequential"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    label="Complemento de Matrícula"
                    placeholder="Informe o complemento"
                    v-model="dependent.registrationComplement"
                    maxlength="50"
                    outlined
                    color="textPrimary"
                    :disabled="loading"
                  />
                </v-col>
              </v-row>
              <v-row v-if="documentsList.length > 0"  align="center" class="mt-10 pr-4">
                <v-col cols="12">
                  <v-row class="pr-4">
                    <v-col cols="12" md="6" align="start">
                      <p class="title">Upload de Documentos</p>
                    </v-col>
                  </v-row>
                  <v-row class="mb-3 ml-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
                    <v-col cols="12" md="5" v-for="(item, index) in documentsList" :key="index">
                      <label class="label"> {{ item.description }}  </label>
                      <v-row>
                        <v-col>
                          <v-file-input
                            v-model="item.file"
                            @change="uploadDocument(item)"
                            placeholder="Clique para adicionar"
                            accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                            prepend-inner-icon="mdi-paperclip"
                            prepend-icon=""
                            color="textPrimary"
                            outlined
                            :disabled="waitingDocumentUpload"
                            :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                            :loading="waitingDocumentUpload"
                            :rules="item.file ? [rule.validateUploadFiles(item)] : []"
                            @click:clear="clearDocument(item)"
                          >
                            <template v-slot:selection="{}">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-chip
                                    small
                                    label
                                    color="primary"
                                    v-on="on"
                                  >
                                    {{ truncateText(item.file.name, 25) }}
                                  </v-chip>
                                </template>
                                <span>{{ item.file.name }}</span>
                              </v-tooltip>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col class="mb-7" align-self="center">
                          <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
                            Baixar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <div v-if="violations.length > 0">
              <ViolationRulesAlert :violations="violations" />
            </div>
          </v-card>
        </v-form>
      </v-col>

      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              class="mr-6"
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
              :disabled="loading"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="white--text"
              :loading="loading && saving"
              x-large
              color="textPrimary"
              width="250px"
              @click="openViewCriticismAndInternalNotesModal()"
              :disabled="loading || waitingDocumentUpload"
            >
              Salvar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>

      <v-dialog v-model="confirmReturnModal" persistent :max-width="600">
        <v-card class="overflow-y-auto pa-5">
          <v-card-title class="pa-5">
            <v-row justify="center">
              <v-col cols="12" align="center">
                <v-icon large color="green">fa-check</v-icon>
                <v-row class="mb-3" justify="center" style="word-break: break-word">
                  <v-col cols="12" align="center">
                    <label class="label primary&#45;&#45;text font-italic" style="word-break: break-word">
                      Movimentação cadastrada com sucesso!
                    </label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-row class="ma-1 justify-center">
              <v-btn class="pa-7" color="#3B495B" style="color: white" @click="closeConfirmReturnModal()">
                IR PARA A LISTA DE MOVIMENTAÇÕES
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ReleaseMovementModal
        ref="ReleaseMovementModal"
        @close="closeConfirmReturnModal()"
        @confirm="updateStatus()"
      />

      <InfoMovementDialog
        :show="showReleaseInfo" :movementType="movementType"
        :messageType="releaseMessageType" :beneficiaryName="dependent && dependent.fullName ? formatter.formatToTitleCase(dependent.fullName) : null" @close="closeConfirmReturnModal()"
      />

      <SnackbarCustomize ref="SnackbarCustomize" />

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="120">
          <div>Carregando...</div>
        </v-progress-circular>
      </v-overlay>

      <div v-if="isCriticizedCarrier || isCriticizedBroker">
        <ViewCriticizedViolationDialog />
      </div>

      <ViewCriticismAndInternalNotesModal
        ref="ViewCriticismAndInternalNotesModal"
        @setSnackbarCustomize="setSnackbarCustomize()"
        @confirmCorrectionMovement="confirmCorrectionMovement"
      />

      <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="openViewCriticismAndInternalNotesModal" />
    </v-row>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import GenderService from '@/services-http/contract/GenderService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import KinshipService from '@/services-http/contract/KinshipService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import UserUtils from '@/shared/utils/user-utils';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import EligibilitiesMixin from '@/shared/mixins/sdi/eligibilitiesMixin';
import PlansMixin from '@/shared/mixins/sdi/plansMixin';
import PrincipalInformations from '@/components/Beneficiary/Holder/PrincipalInformations.vue';
import PlanInformation from '@/components/Beneficiary/Contracted/PlanInformation.vue';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import { store } from '@/shared/observable/store';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';

export default {
  name: 'dependentAlteration',
  components: {
    InfoMovementDialog,
    ViolationRulesAlert,
    ViewCriticizedViolationDialog,
    PrincipalInformations,
    PlanInformation,
    ReleaseMovementModal,
    ViewCriticismAndInternalNotesModal,
    SnackbarCustomize,
    CriticismHandlingModal,
  },
  data: () => ({
    dependentIdParam: null,
    dependentsDefault: [],
    documentsList: [],
    documentsData: [],
    dependent: {
      id: 0,
      beneficiary: { name: null },
      document: null,
      fullName: null,
      birthDate: null,
      maritalStatus: null,
      gender: null,
      kinship: null,
      cns: null,
      dnv: null,
      motherName: null,
      height: null,
      weight: null,
      stateBirth: null,
      validityDate: null,
      eventDate: null,
      plans: [],
      documents: [],
      familyCode: null,
      familyCodeSequential: null,
      registrationComplement: null,
    },
    genders: [],
    loading: false,
    maritalStatus: [],
    kinships: [],
    saving: false,
    sessionStorageName: 'dependentsUpdated',
    valid: true,
    isRHProtegido: false,
    controlHeight: false,
    controlWeight: false,
    financialGroups: [],
    contracts: [],
    movementRecord: {},
    financialGroupName: '',
    contractName: '',
    subContractId: null,
    planId: null,
    isFreeMovement: false,
    loadingSubContracts: false,
    loadingPlans: false,
    holder: {
      name: null,
      cpf: null,
      registrationPlate: null,
      cardNumber: null,
    },
    subContracts: [],
    eligibilitiesIds: [],
    formatHolderCpf: null,
    codeMessage: '',
    formatBirthDate: null,
    formatEventDate: null,
    formatValidityDate: null,
    contractPlanId: null,
    waitingDocumentUpload: false,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    isDisabledFamilyCode: false,
    isDisabledFamilyCodeSequential: false,
    isEdit: false,
    overlay: false,
    confirmReturnModal: false,
    violations: [],
    hasAuthorityReleaseMovement: false,
    showReleaseInfo: false,
    movementType: 'Alteração Dependente',
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',
    waitingRulesApprovalIds: [],
    payload: {},
    contractedPlanData: {
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      eligibilityId: null,
      planId: null,
    },
    holderName: '',
    internalCriticisms: [],
    skipDuplicityCheck: false,
    persistWithCriticism: false,
  }),

  async mounted() {
    this.verifyFreeMovement();
    this.verifyTypeOperationForMovement();
    this.loadAuthorityMovement();
    this.loadGenders();
    this.loadMaritalStatus();
    this.loadKinships();
    await this.loadDocumentType();
    await this.loadBeneficiary();
  },

  mixins: [
    ConstantsMixin,
    FinancialGroupsMixin,
    ContractsMixin,
    EligibilitiesMixin,
    PlansMixin,
    VerifyRoutesMixin,
  ],

  computed: {
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  watch: {
    getObservableStore(val) {
      if (val && !val.show) {
        if (val.viewMovements) {
          sessionStorage.setItem('movementRecordIds', JSON.stringify(val.data.movementRecordIds));
          this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
        }

        if (val.skipDuplicityCheck) {
          this.skipDuplicityCheck = true;
          this.openViewCriticismAndInternalNotesModal();
        }
      }
    },
  },

  methods: {
    verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
          this.genders.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
          this.maritalStatus.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadKinships() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data;
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadBeneficiary() {
      if (sessionStorage.getItem('movementRecord')) {
        await this.mapBeneficiaryMovementRecord();
      }
      if (sessionStorage.getItem('planInfo')) {
        this.contractedPlanData = {
          financialGroupId: JSON.parse(sessionStorage.getItem('planInfo')).financialGroupSelected.id,
          contractId: JSON.parse(sessionStorage.getItem('planInfo')).contractSelected.id,
        };
        this.getSubContractsByContractId(this.contractedPlanData.contractId);
      }
      if (sessionStorage.getItem('dependentAlteration')) {
        const dependentAlteration = JSON.parse(sessionStorage.getItem('dependentAlteration'));
        this.holderName = dependentAlteration.parentName;

        this.contractedPlanId = dependentAlteration.contractPlanId;
        this.contractedPlanData.financialGroupId = dependentAlteration.financialGroupId ? dependentAlteration.financialGroupId : null;

        this.dependent.memberId = dependentAlteration.memberId;
        this.dependent.document = dependentAlteration.physicalPersonCPF ? this.formatter.removeNonDigit(dependentAlteration.physicalPersonCPF) : null;
        this.dependent.fullName = dependentAlteration.physicalPersonName ? this.formatter.formatToTitleCase(dependentAlteration.physicalPersonName) : null;
        this.dependent.birthDate = dependentAlteration.physicalPersonBirthDate ? this.formatter.formatDate(dependentAlteration.physicalPersonBirthDate) : null;
        this.dependent.maritalStatus = dependentAlteration.maritalStatusId ? dependentAlteration.maritalStatusId : null;
        this.dependent.gender = dependentAlteration.genderId;
        this.dependent.kinship = dependentAlteration.kinshipId;
        this.dependent.cns = dependentAlteration.cns;
        this.dependent.dnv = dependentAlteration.dnv ? this.formatter.unmaskDnv(dependentAlteration.dnv) : null;
        this.dependent.motherName = dependentAlteration.mothersName ? this.formatter.formatToTitleCase(dependentAlteration.mothersName) : null;
        this.dependent.height = dependentAlteration.height;
        this.dependent.weight = dependentAlteration.weight;
        this.dependent.stateBirth = dependentAlteration.stateBirth;
        this.dependent.validityDate = dependentAlteration.admissionDate ? this.formatter.formatDate(dependentAlteration.admissionDate) : null;
        this.dependent.eventDate = dependentAlteration.eventDate ? this.formatter.formatDate(dependentAlteration.eventDate) : null;
        this.dependent.familyCode = dependentAlteration.memberFamilyCode ? dependentAlteration.memberFamilyCode : null;
        this.dependent.familyCodeSequential = dependentAlteration.memberFamilyCodeSeq ? dependentAlteration.memberFamilyCodeSeq : null;
        this.dependent.registrationComplement = dependentAlteration.registrationComplement ? dependentAlteration.registrationComplement : null;
      }

      this.isDisabledFamilyCode = this.dependent.familyCode !== null;
      this.isDisabledFamilyCodeSequential = this.dependent.familyCodeSequential !== null;
    },
    async mapBeneficiaryMovementRecord() {
      this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
      if (this.movementRecord) {
        this.holderName = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;

        this.contractedPlanData.financialGroupId = this.movementRecord.financialGroupId ? this.movementRecord.financialGroupId : null;
        this.contractedPlanData.contractId = this.movementRecord.contractId ? this.movementRecord.contractId : null;
        this.contractPlanId = this.movementRecord.contractedPlanId ? this.movementRecord.contractedPlanId : null;
        this.contractedPlanData.subContractId = this.movementRecord.subContractId ? Number(this.movementRecord.subContractId) : null;
        this.contractedPlanData.planId = this.movementRecord.plan ? Number(this.movementRecord.plan) : null;

        this.dependent.document = this.movementRecord.insuredDocumentNumber ? this.movementRecord.insuredDocumentNumber : null;
        this.dependent.fullName = this.movementRecord.insuredName ? this.formatter.formatToTitleCase(this.movementRecord.insuredName) : null;
        this.dependent.birthDate = this.movementRecord.birthDate ? this.formatter.formatDate(this.movementRecord.birthDate) : null;
        this.dependent.maritalStatus = this.movementRecord.maritalStatus ? this.movementRecord.maritalStatus : null;
        this.dependent.gender = this.movementRecord.gender ? this.movementRecord.gender : null;
        this.dependent.motherName = this.movementRecord.motherName ? this.formatter.formatToTitleCase(this.movementRecord.motherName) : null;
        this.dependent.kinship = this.movementRecord.degreeKinship ? this.movementRecord.degreeKinship : null;
        this.dependent.cns = this.movementRecord.cns ? this.movementRecord.cns : null;
        this.dependent.dnv = this.movementRecord.dnv ? this.movementRecord.dnv : null;
        this.dependent.height = this.movementRecord.height ? this.movementRecord.height : null;
        this.dependent.weight = this.movementRecord.weight ? this.movementRecord.weight : null;
        this.dependent.stateBirth = this.movementRecord.stateBirth ? this.movementRecord.stateBirth : null;
        this.dependent.validityDate = this.movementRecord.startDate ? this.formatter.formatDate(this.movementRecord.startDate) : null;
        this.dependent.eventDate = this.movementRecord.eventDate ? this.formatter.formatDate(this.movementRecord.eventDate) : null;
        this.dependent.familyCode = this.movementRecord.familyCode ? this.movementRecord.familyCode : null;
        this.dependent.familyCodeSequential = this.movementRecord.familyCodeSequential ? this.movementRecord.familyCodeSequential : null;
        this.dependent.registrationComplement = this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null;
        this.holder.document = this.movementRecord.holderDocumentNumber ? this.formatter.formatCpf(this.movementRecord.holderDocumentNumber) : null;
        this.holder.name = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;
        this.holder.registrationPlate = this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null;
        this.holder.cardNumber = this.movementRecord.beneficiaryCard ? this.movementRecord.beneficiaryCard : null;
        this.loadDocumentsByMovementRecordId();
      }
    },
    async loadDocumentsByMovementRecordId() {
      const queryString = `?movementRecordId=${this.movementRecord.id}`;
      this.documentService.FindDocumentByFilters(queryString).then(async (response) => {
        if (response && response.data && response.data.length > 0) {
          if (this.documentsList && this.documentsList.length > 0) {
            await new Promise((resolve) => {
              resolve(this.documentsList.forEach((documentList) => {
                response.data.forEach((document) => {
                  if (documentList.name === document.documentType.name) {
                    // eslint-disable-next-line no-param-reassign
                    documentList.file = new File([document.name], document.name, { type: 'image/png' });
                    // eslint-disable-next-line no-param-reassign
                    documentList.documentTypeId = documentList.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.id = document.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.name = document.name;
                    // eslint-disable-next-line no-param-reassign
                    documentList.loadingDownload = false;
                  }
                });
              }));
            });
            this.loadDocumentsInDependent();
          }
        }
      });
    },
    loadDocumentsInDependent() {
      this.documentsList.forEach((document) => {
        if (document && document.documentTypeId) {
          this.dependent.documents.push(document);
        }
      });
    },
    onClickCancel() {
      const { isRHProtegido } = this;
      let name;

      if (this.isEdit || this.isCriticizedBroker || this.isCriticizedCarrier) {
        name = 'Movement';
      } else {
        name = 'SearchHolderDependent';
      }

      this.redirectRouter(name, { isRHProtegido });
    },
    openViewCriticismAndInternalNotesModal(isContinue) {
      this.persistWithCriticism = !!isContinue;
      if (this.isCriticizedCarrier) {
        const queryParams = {
          movementRecord: this.movementRecord,
          isRHProtegido: this.isRHProtegido,
          viewType: 'confirmCorrectionMovement',
        };

        this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
      } else {
        this.onClickSave();
      }
    },
    confirmCorrectionMovement(data) {
      this.internalCriticisms = data;
      this.$refs.ViewCriticismAndInternalNotesModal.close();
      this.onClickSave();
    },
    async onClickSave() {
      this.saving = true;
      this.loading = true;

      const data = this.prepareData();
      this.violations = [];
      this.payload = data;

      if (this.isEdit || this.isCriticizedBroker) {
        await this.handleEditAndCriticizedBroker();
      } else {
        await this.handleFreeMovement();
      }
    },
    prepareData() {
      const formattedBirthDate = this.dependent.birthDate ? this.formatter.formatDateBRtoString(this.dependent.birthDate) : null;
      const formattedEventDate = this.dependent.eventDate ? this.formatter.formatDateBRtoString(this.dependent.eventDate) : null;
      const formattedValidityDate = this.dependent.validityDate ? this.formatter.formatDateBRtoString(this.dependent.validityDate) : null;

      return {
        contractedPlanId: this.contractedPlanId,
        financialGroupId: this.contractedPlanData.financialGroupId || null,
        dependentId: this.dependent.memberId || null,
        document: this.dependent.document ? this.formatter.removeNonDigit(this.dependent.document) : null,
        fullName: this.dependent.fullName || null,
        birthDate: formattedBirthDate,
        maritalStatus: this.dependent.maritalStatus || null,
        gender: this.dependent.gender || null,
        relationship: this.dependent.kinship || null,
        cns: this.dependent.cns || null,
        dnv: this.dependent.dnv ? this.dependent.dnv.replaceAll('-', '') : null,
        motherName: this.dependent.motherName || null,
        height: this.dependent.height || null,
        weight: this.dependent.weight || null,
        stateBirth: this.dependent.stateBirth || null,
        documents: this.documentFormatted(),
        plans: [],
        validityDate: formattedValidityDate || null,
        eventDate: this.conditionShowEventDate() ? formattedEventDate : null,
        familyCode: this.dependent.familyCode,
        familyCodeSequential: this.dependent.familyCodeSequential,
        internalCriticisms: this.internalCriticisms,
        registrationComplement: this.dependent.registrationComplement,
        skipDuplicityCheck: this.skipDuplicityCheck ? this.skipDuplicityCheck : false,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
        ...(this.isCriticizedCarrier ? { movementRecordParentId: this.movementRecord.movementRecordParentId || null } : {}),
      };
    },
    async handleEditAndCriticizedBroker() {
      await this.formatPayloadEditAndCriticizedBroker();
      await this.processMovementRecordService(
        this.movementRecordService.UpdateAlterationDependent,
        this.movementRecord.id,
        this.payload,
        this.isFreeMovement,
        this.isCriticizedBroker,
      );
    },
    async handleFreeMovement() {
      await this.formatPayloadForFreeMovement();
      this.processMovementRecordService(
        this.movementRecordService.UpdateDependent,
        this.payload,
        this.isFreeMovement,
      );
    },
    async formatPayloadEditAndCriticizedBroker() {
      const { payload } = this;
      this.payload = {
        contractId: this.contractedPlanData.contractId,
        subContractId: this.contractedPlanData.subContractId,
        financialGroupId: this.contractedPlanData.financialGroupId,
        planId: this.contractedPlanData.planId,
        eligibilityId: this.contractedPlanData.eligibilityId,
        dependentName: payload.fullName,
        dependentCpf: payload.document,
        birthDate: payload.birthDate,
        maritalStatusId: payload.maritalStatus,
        genderId: payload.gender,
        degreeKinshipId: payload.relationship,
        cns: payload.cns,
        dnv: payload.dnv ? this.formatter.unmaskDnv(payload.dnv) : null,
        motherName: payload.motherName,
        height: payload.height,
        weight: payload.weight,
        validityDate: payload.validityDate,
        stateBirth: payload.stateBirth,
        eventDate: payload.eventDate,
        holderName: this.holder.name,
        holderCpf: this.holder.document ? this.formatter.removeNonDigit(this.holder.document) : null,
        holderRegistrationPlate: this.holder.registrationPlate,
        holderCardNumber: this.holder.cardNumber,
        documents: this.documentFormatted(),
        familyCode: payload.familyCode,
        familyCodeSequential: payload.familyCodeSequential,
      };

      const uniqueDocumentIds = new Set();
      const filteredDocuments = this.payload.documents.filter((document) => {
        if (!uniqueDocumentIds.has(document.id)) {
          uniqueDocumentIds.add(document.id);
          return true;
        }
        return false;
      });

      this.payload.documents = filteredDocuments;
    },
    async formatPayloadForFreeMovement() {
      if (this.isFreeMovement) {
        this.payload.holder = {
          name: this.holder.name,
          cpf: this.holder.document ? this.formatter.removeNonDigit(this.holder.document) : null,
          registrationPlate: this.holder.registrationPlate,
          cardNumber: this.holder.cardNumber,
        };
        this.payload.plans.push({
          financialGroupId: this.contractedPlanData.financialGroupId,
          contract: this.contractedPlanData.contractId,
          subcontract: this.contractedPlanData.subContractId,
          plan: this.contractedPlanData.planId,
        });
        delete this.payload.contractedPlanId;
      }
    },
    async processMovementRecordService(serviceMethod, ...args) {
      this.violations = [];
      this.overlay = true;

      try {
        const response = await serviceMethod.call(this.movementRecordService, ...args);
        const { recordList } = response.data;

        this.waitingRulesApprovalIds = recordList.filter((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS').map((movement) => movement.id);

        if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
          const releaseMovementData = {
            beneficiaryName: this.dependent.fullName,
            movementType: this.movementType,
          };

          this.$refs.ReleaseMovementModal.open(releaseMovementData);
        } else {
          this.openConfirmReturnModal();
        }
      } catch (error) {
        this.overlay = false;
        this.loading = false;
        this.documentsData = [];
        this.holder.document = this.formatHolderCpf;

        if (error.response && error.response.status === 422) {
          this.violations = [...error.response.data.violations];
        }
      }
    },
    documentFormatted() {
      const documentFormatted = this.documentsList.filter((element) => element.file !== null && typeof element.file !== 'undefined').map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },
    async loadDocumentType() {
      const route = this.$route.query;
      const query = `movementType=${route.movementType}&insuranceCarrierId=${route.insuranceCarrierId}&beneficiaryType=${route.beneficiaryType}`;
      await this.findDocumentType(query);
    },
    async findDocumentType(query) {
      await this.documentTypeService.FindAllDocumentType(query).then(async (response) => {
        this.documentsList = await response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      });
    },
    async uploadDocument(item) {
      if (item) {
        if ((item.file && (item.file.size > 10485760)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png'))) {
          return;
        }
      }
      if (item.file !== null && typeof item.file !== 'undefined') {
        await this.clearDocument(item);
        const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
        this.waitingDocumentUpload = true;
        this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
          this.documentsData.push({
            id: response.data,
            name: item.name,
            file: item.file,
            type: item.file.type,
          });
          // eslint-disable-next-line no-param-reassign
          item.idRecentlyUploaded = response.data;
          this.waitingDocumentUpload = false;
          this.pushUploadDocument(response, item);
        }).catch(() => {
          this.waitingDocumentUpload = false;
        });
      } else {
        await this.clearDocument(item);
      }
    },
    pushUploadDocument(response, item) {
      this.dependent.documents.push({
        id: response.data,
        name: item.name,
        file: item.file,
        type: item.file.type,
      });
    },
    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex((document) => document.documentTypeId === item.documentTypeId);
          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
          }
        }
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    conditionShowEventDate() {
      if (this.dependent.kinship && (this.kinships && this.kinships.length > 0)) {
        const relationshipSelected = this.kinships.find((kinship) => kinship.id === this.dependent.kinship);
        switch (relationshipSelected.code) {
          case 'tutelado':
          case 'marido':
          case 'esposa':
          case 'conjuge':
          case 'companheiro':
          case 'filhoadotivo':
          case 'enteado':
            return true;

          default: return false;
        }
      }
      return false;
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.$refs.ReleaseMovementModal.close();
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
      this.saving = false;
      this.loading = false;
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao' });
    },
    async updateStatus() {
      const movementRecordService = new MovementRecordService();
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.releaseMessageType = this.successReleaseMessageType;
          this.showReleaseInfo = true;
          this.setSnackbarCustomize('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.showReleaseInfo = true;
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.releaseMessageType = this.errorReleaseMessageType;
        this.showReleaseInfo = true;
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    observerHolderInformationData(data) {
      this.holder = data;
    },
    observerContractedPlan(data) {
      this.contractedPlanData = data;
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
  },
  async created() {
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.loading = true;
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.kinshipService = new KinshipService();
    this.movementRecordService = new MovementRecordService();
    this.loading = false;
    this.userUtils = new UserUtils();
  },
};
</script>

<style scoped>
</style>
